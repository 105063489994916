import Image from 'next/image';

import Carousel from 'react-material-ui-carousel';

import { customLoader } from '../../../utils/customLoaderImage';
import BannerDefault from '../../BannerDefault';

import style from './carouselDepoimentos.module.scss';

export default function DepoimentosCarousel({ depoimentos = [] }) {
  if (!Array.isArray(depoimentos)) {
    depoimentos = [];
  }

  if (typeof window !== 'undefined') {
    Array.from(document.getElementsByClassName('MuiButtonBase-root')).forEach((element) => {
      const ariaElement = element.getAttribute('aria-label')?.slice(0, 18);

      if (ariaElement && ariaElement === 'carousel indicator') element.setAttribute('tabindex', '-1');
    });
  }

  if (!depoimentos.length) return null;

  return (
    <BannerDefault
      image={{ src: '/img/backgroundpolitica.webp' }}
      width='100%'
      height='fit-content'>
      <div className={style.section}>
        <h2>Experiências Extraordinárias</h2>
        <Carousel
          navButtonsAlwaysVisible
          autoPlay
          className={style.slider}>
          {depoimentos.map((depoimento) => (
            <div
              className={style.slide}
              key={depoimento?.nome_cliente}>
              <p>{depoimento?.depoimento}</p>
              <div className={style.cliente}>
                <figure className={style.image}>
                  <Image
                    alt={depoimento?.nome_cliente}
                    src={depoimento?.imagem.url}
                    loader={customLoader}
                    layout='responsive'
                    width='60'
                    height='60'
                    priority
                  />
                  <figcaption>
                    Imagem:
                    {`Foto do cliente ${depoimento.nome_cliente}`}
                  </figcaption>
                </figure>
                <div className={style.infoClient}>
                  <span>{depoimento.nome_cliente}</span>
                  <span className={style.emp}>{depoimento.empreendimento}</span>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </BannerDefault>
  );
}
