import Banner from '../../components/BannerDefault';
import CardCarouselBlog from '../../components/Cards/CardCarouselBlog';
import CarouselCardsDefault from '../../components/Carousel/CarouselCardsDefault';

import style from './noticias.module.scss';

export default function Noticias({ artigos, background }) {
  if (!artigos) return null;

  const arrayCards = [
    artigos.map((art) => (
      <CardCarouselBlog
        key={art.id}
        artigo={art}
      />
    )),
  ];

  return (
    <Banner
      image={{ src: background?.imagem.url }}
      width='100%'
      height='fit-content'>
      <div className={style.section}>
        <h2>
          Confira nossas
          {' '}
          <br />
          {' '}
          últimas
          {' '}
          <span>notícias!</span>
        </h2>
        <CarouselCardsDefault seeMore='/blog'>{arrayCards}</CarouselCardsDefault>
      </div>
    </Banner>
  );
}
