import Banner from '../../components/BannerDefault';

import css from './formfale.module.scss';

export default function Formfale() {
  return (
    <div
      hidden
      className={css.bgfale}>
      <Banner
        image={{ src: '/img/fundocardvalores.webp' }}
        width='100%'
        height='fit-content'>
        <div className={css.section}>
          <div>
            <h2 className={css.titulofale}>
              Fale com a gente, estamos
              {' '}
              <br />
              {' '}
              aqui para te
              {' '}
              <span className={css.textdec}>ajudar!</span>
            </h2>
          </div>
          <div className={`${css.padding} mt-5`}>
            <input
              className={css.inputform}
              placeholder='Nome completo'
              type='text'
            />
            <input
              className={css.inputform}
              placeholder='Endereço de e-mail'
              type='text'
            />
            <input
              className={css.inputform}
              placeholder='Telefone'
              type='text'
            />
            <input
              className={css.inputform}
              placeholder='Mensagem'
              type='text'
            />
            <div className={css.buttonenviar}>Enviar mensagem!</div>
          </div>
        </div>
      </Banner>
    </div>
  );
}
