export default function MetaTags({ data }) {
  if (!data) return null;

  return (
    <>
      {/* HTML Meta Tags */}
      <title>
        {' '}
        {data?.titulo ?? ''}
        {' '}
      </title>
      <meta
        name='description'
        content={data?.descricao ?? ''}
        key='metadescription'
      />

      {/* Google / Search Engine Tags */}
      <meta
        itemProp='name'
        content={data?.titulo ?? ''}
        key='googlename'
      />
      <meta
        itemProp='description'
        content={data?.descricao ?? ''}
        key='googledescription'
      />
      <meta
        itemProp='image'
        content={data?.imagem?.url ?? ''}
        key='googleimage'
      />

      {/* Facebook Meta Tags */}
      <meta
        property='og:url'
        content={data?.rota ?? ''}
        key='ogurl'
      />
      <meta
        property='og:type'
        content='website'
        key='ogtype'
      />
      <meta
        property='og:title'
        content={data?.titulo ?? ''}
        key='ogtitle'
      />
      <meta
        property='og:description'
        content={data?.descricao ?? ''}
        key='ogdesc'
      />
      <meta
        itemProp='image'
        content={data?.imagem?.url ?? ''}
        key='image'
      />
      <meta
        property='og:image'
        itemProp='image'
        content={data?.imagem?.url ?? ''}
        key='ogimage'
      />
      <meta
        property='og:image:width'
        content={data?.imagem?.width?.toString() ?? ''}
        key='ogimagewidth'
      />
      <meta
        property='og:image:height'
        content={data?.imagem?.height?.toString() ?? ''}
        key='ogimageheight'
      />
      <meta
        property='og:site_name'
        content='Hype Empreendimentos'
        key='ogsitename'
      />

      {/* Twitter Meta Tags */}
      <meta
        name='twitter:card'
        content='summary'
      />
      <meta
        name='twitter:title'
        content={data?.titulo ?? ''}
        key='twittertitle'
      />
      <meta
        name='twitter:description'
        content={data.descricao ?? ''}
        key='twitterdescription'
      />
      <meta
        name='twitter:image'
        content={data?.imagem?.url ?? ''}
        key='twitterimage'
      />
      <meta
        property='twitter:domain'
        content='hypeempreendimentos.com.br'
        key='twitterdomain'
      />
      <meta
        property='twitter:url'
        content={data?.rota ?? ''}
        key='twitterurl'
      />
    </>
  );
}
