import ButtonDefault from '../../components/Buttons/ButtonDefault';
import CardCarouselLancamento from '../../components/Cards/CardCarouselLancamento';
import CarouselCardsDefault from '../../components/Carousel/CarouselCardsDefault';

import style from './lancamentos.module.scss';

export default function NossosLancamentos({ lancamentos, texto }) {
  if (!lancamentos || !texto) return null;

  const arrayCards = [
    lancamentos?.map((lancamento) => (
      <CardCarouselLancamento
        key={lancamento?.id}
        lancamento={lancamento}
      />
    )),
  ];

  return (
    <div className={style.section}>
      <div className={style.text}>
        <h2>{texto.texto.titulo}</h2>
        <p>{texto.texto.conteudo}</p>
        <ButtonDefault
          url='/apartamentos-a-venda'
          text='Ver mais'
          aria='Ver mais'
        />
      </div>
      <CarouselCardsDefault seeMore='/apartamentos-a-venda'>{arrayCards}</CarouselCardsDefault>
    </div>
  );
}
