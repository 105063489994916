import Link from 'next/link';

import style from './buttonDefault.module.scss';

interface ButtonDefaultProps {
  url: string;
  text: string;
  aria: string;
  target?: string;
}

export default function ButtonDefault({
  url, text, aria, target = '_self',
}: ButtonDefaultProps) {
  return (
    <div className={style.buttonContainer}>
      <Link
        href={url ?? ''}
        passHref>
        <a
          target={target}
          rel='noopener noreferrer'>
          <button
            type='button'
            aria-label={aria}>
            {text}
          </button>
        </a>
      </Link>
    </div>
  );
}
