import Image from 'next/image';

import React from 'react';

import { customLoader } from '../../utils/customLoaderImage';

import styles from './bannerDefault.module.scss';

interface BannerProps {
  image: {
    src: string;
  };
  children: React.ReactNode;
  width: string;
  height: string;
}

export default function Banner({
  image, children = '', width = '100%', height = 'fit-content',
}: BannerProps) {
  return (
    <div
      className={styles.bannerContainer}
      style={{ width, height }}>
      <Image
        src={image?.src || '/img/loading-wall.gif'}
        loader={customLoader}
        width={width}
        layout='fill'
        objectFit='cover'
        quality={100}
        alt='Hype Empreendimentos'
        priority
      />
      <div className={styles.childrenContainer}>{children}</div>
    </div>
  );
}
