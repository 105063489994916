import Image from 'next/image';

import React from 'react';

import ButtonDefault from '../../components/Buttons/ButtonDefault';
import { customLoader } from '../../utils/customLoaderImage';

import style from './sobreHype.module.scss';

export default function Sobrehype({ dados }) {
  if (!dados) return null;

  const {
    banner, texto1, texto2, texto3,
  } = dados;

  return (
    <div
      id='quemsomos'
      className={style.section}>
      <div className={style.text}>
        <h2>{texto1.texto.titulo}</h2>
        <p>{texto1.texto.conteudo}</p>
        <div className={style.info}>
          <div className={style.itemInfo}>
            <span>{texto2.texto.titulo}</span>
            <h3>{texto2.texto.conteudo}</h3>
          </div>
          <div className={style.itemInfo}>
            <span>{texto3.texto.titulo}</span>
            <h3>{texto3.texto.conteudo}</h3>
          </div>
        </div>
        <ButtonDefault
          url='/sobre-nos'
          text='Saiba mais sobre a Hype!'
          aria='Saiba mais sobre a Hype!'
        />
      </div>

      <figure className={style.image}>
        <Image
          layout='responsive'
          objectFit='cover'
          quality={100}
          src={banner?.url || '/img/image_icon.png'}
          loader={customLoader}
          alt='imagem do predio da sede da Hype'
          width={`${banner?.width}`}
          height={`${banner?.height}`}
          priority
        />
        <figcaption>Imagem do predio da sede da Hype</figcaption>
      </figure>
    </div>
  );
}
