import Image from 'next/image';
import Link from 'next/link';

import { IoIosArrowDropright } from 'react-icons/io';

import { customLoader } from '../../../utils/customLoaderImage';
import { imagemSizes } from '../../../utils/ImageFindSizes';

import style from './cardLancamento.module.scss';

export default function Lancamentos({ lancamento }) {
  if (!lancamento) return null;

  return (
    <Link
      href={`/apartamentos-a-venda/${lancamento.slug}`}
      passHref>
      <div
        className={style.item}
        key={lancamento.slug}>
        <h3>{lancamento.title}</h3>
        <figure>
          <Image
            src={imagemSizes({ imagem: lancamento?.image }) || '/img/image_icon.png'}
            loader={customLoader}
            width={lancamento?.image.width}
            height={lancamento?.image.height}
            alt={lancamento.title}
          />
          <figcaption>
            Imagem:
            {lancamento.title}
          </figcaption>
        </figure>

        <a
          role='button'
          onKeyDown={(event) => event.key === 'Enter' && event.currentTarget.click()}
          tabIndex={0}>
          <span>Ver mais</span>
          <IoIosArrowDropright />
        </a>
      </div>
    </Link>
  );
}
